<template>
  <div class="signView">
    <slot></slot>
    <div class="flexXY midZone">
      <div class="signPanle p4 shadow bgGlass">
        <div class="appLogoZOne">
          <span class="flexX logoItem"></span>
          <span class="flexVXY fontMd noSelect">
            <span class="fontW7 prodNameCell" v-html="appInfo.appNameHtml || ''"></span>
            <span class="fontW1 fontSm prodNameCell">v{{ appInfo.appVer }}</span>
          </span>
        </div>
        <div class="iptZone">
          <div></div>
          <div class="iptRow">
            <span class="fontSm fontW7 textRight">用户名：</span>
            <input ref="iptUname" class="signIpt" v-model="uName" type="text" placeholder="请输入账号" autofocus
              @keydown.enter.prevent.stop="nextStep" @focus="onFocusSelect" />
          </div>
          <div class="iptRow">
            <span class="fontSm fontW7 textRight">密码：</span>
            <input ref="iptUpwd" class="signIpt" v-model="uPwd" :type="uPwd ? 'password' : 'text'" placeholder="请输入密码"
              @keydown.enter.prevent.stop="execSign" @focus="onFocusSelect" />
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="tLottie">
      <TLottie :options="animOpt" :width="200" :height="200"></TLottie>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import TLottie from '@/components/animate/TLottie.vue';
import { useUser } from '@/stores/sys/useUser';
import { useApp } from '@/stores/sys/useApp';
import { dropCache } from '@/util/cacheKit/getCache';
import { onFocusSelect } from '@/util/uiKit/onFocusSelect';
import { onKeyStroke } from '@vueuse/core';

const emit = defineEmits<{
  (e: 'onSigned', val: boolean): void
}>();

const userMgr = useUser();
const appMgr = useApp();
// const pageMgr = usePage();
const appInfo = appMgr.getApp;

const uName = ref('');
const uPwd = ref('');
const iptUname = ref<HTMLInputElement | null>(null);
const iptUpwd = ref<HTMLInputElement | null>(null);
const animOpt = {
  loop: true,
  autoplay: true,
  assetsPath: '/static/aminat/sign.json'
};

const nextStep = async () => {
  if (uPwd.value) {
    await execSign();
  } else {
    iptUpwd.value!.focus();
  }
};

const execSign = async () => {
  await userMgr.signIn(uName.value, uPwd.value);
  emit('onSigned', true);
};

// const ensureSign = (e: boolean) => {
//   if (e) {
//     pageMgr.ensureSign();
//   }
// };

// /** 页面按下 ctrl 键，聚焦用户名输入框 */
// const setForcus = (e: KeyboardEvent) => {
//   e.stopPropagation();
//   if (e.ctrlKey && iptUname.value) {
//     iptUname.value.focus();
//   } else {
//     return;
//   }
// };

onKeyStroke((e: KeyboardEvent) => {
  if (e.ctrlKey && iptUname.value) {
    iptUname.value.focus();
  }
});

onMounted(async () => {
  await dropCache();
});
</script>

<style lang="scss" scoped>
.signView {
  position: relative;
  display: grid;
  grid-template-rows: 1fr 32px;
  width: 100vw;
  height: 100vh;

  &::before {
    content: '';
    content: '';
    position: fixed;
    width: 100vw;
    height: 15vh;
    left: 0;
    top: 0;
    background: transparent url(/static/img/bgTop.png) right top no-repeat;
    background-size: contain;
    z-index: -1;
  }

  &::after {
    position: fixed;
    content: '';
    width: 100vw;
    height: 394px;
    bottom: 0;
    left: 0;
    background: transparent url(/static/img/bgH.png) left bottom no-repeat;
    background-size: contain;
    z-index: -1;
  }

  .midZone {
    position: relative;

    &::after {
      position: fixed;
      content: '';
      will-change: transform;
      transform: translate(-50%, -60%);
      z-index: 0;
      top: 50%;
      left: 45%;
      width: 72vw;
      height: 64vh;
      border-radius: 50% 22% 40% 80%;
      filter: blur(90px);
      opacity: .45;
      background-image: radial-gradient(circle at 40% 20%, #ffb87a 0, transparent 50%), radial-gradient(circle at 80% 0, #1fddff 0, transparent 50%), radial-gradient(circle at 0 50%, #ffdbde 0, transparent 50%), radial-gradient(circle at 80% 100%, #6b66ff 0, transparent 50%), radial-gradient(circle at 0 0, #ff85a7 0, transparent 50%);
    }
  }

  .signPanle {
    display: grid;
    grid-template-columns: 120px 1fr;
    width: 480px;
    height: 240px;
    border-radius: 20px;
    border: 1px #dad5d1 solid;
    // background: var(--bg-color-brand);
    // backdrop-filter: blur(10px);
    // box-shadow: 1px 1px 16px 4px rgb(39 80 169);
    box-shadow: 0 0 10px 2px rgb(39 80 169);
    background-color: hsl(230.28deg 66.39% 60.61% / 19%);
    backdrop-filter: blur(10px) opacity(0.5);
    z-index: 99999;

    .appLogoZOne {
      display: grid;
      grid-template-rows: 120px 1fr;
      justify-content: center;
      border-right: 1px #dfddda dashed;
      padding-right: 16px;

      .prodNameCell {
        filter: drop-shadow(2px 4px 6px black);
        text-shadow: 1px 1px 4px var(--proj-main-color);
      }

      .logoItem {
        background: transparent url(/static/logo/favicon.png) center center no-repeat;
        // background-size: contain;
        background-size: 60%;
        // filter: drop-shadow(2px 4px 6px black);
        filter: drop-shadow(2px 4px 6px #38439b);
      }
    }

    .iptZone {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      row-gap: 16px;
      padding-left: 8px;

      .iptRow {
        display: grid;
        grid-template-columns: 64px 1fr;
        align-items: center;

        input.signIpt {
          width: 100%;
          height: 24px;
          padding: 14px 18px;
          border-radius: 50px;
          color: #333;
          font-size: 14px;
          letter-spacing: 3px;
          border: 1px #a8a7a5 solid;
          outline: none;
          background-color: hsla(0, 0%, 100%, 0.5);

          &:focus {
            // border-color: transparent;
            border-color: #5C516B;
          }
        }
      }
    }
  }

  .tLottie {
    position: fixed;
    right: 100px;
    bottom: 54px;
    z-index: 9999;
  }
}
</style>
