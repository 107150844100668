import type { TMenuGoup, TOriginBtn } from 'shangshi_types';

const notBill: TMenuGoup[] = ['home', 'dict', 'master', 'setting', 'report']; // 非表单
const notBillAndRpt: TMenuGoup[] = ['home', 'dict', 'master', 'setting']; // 非表单和报表
const notCrud: TMenuGoup[] = ['home', 'dict', 'report'];  // 禁止执行 CRUD 操作的业务
const notBiz: TMenuGoup[] = ['home', 'dict', 'setting'];  // 非业务相关，如首页，字典和设置
const notMasterOrSetting: TMenuGoup[] = ['home', 'dict', 'bill', 'report'];  // 非业务相关，如首页，字典和设置

// const billProdBatch: string[] = ['saleOrder', 'saleNotice', 'purchContract', 'purchNotice', 'purchSalesContract'];
// const billInventryView: string[] = ['saleOrder', 'saleNotice', 'saleOut', 'saleRedOut', 'purchNotice', 'purchContract', 'purchSalesContract', 'purchIn'];
// const billCounterPriceView: string[] = ['saleOrder', 'saleNotice'];
const clearanceView: string[] = ['payableClearanceSummary', 'receivableClearanceSummary'];  // 收款或付款核销页面
const noDeliver: string[] = ['deliverDemandQuery']; // 对送货管理的部分业务屏蔽重新选择，使用直接页面的筛选

export const aloneBtnGropu: TMenuGoup[] = ['home', 'bill', 'setting', 'master', 'report'];
export const masterBtnGropuOnly: TMenuGoup[] = ['home', 'bill', 'setting'];

/** 系统的业务操作按钮原始定义全集 */
const btns: TOriginBtn[] = [
  { id: 0, code: 'onAdd', namezh: '新增', showState: ['list', 'view'], width: 80, type: 'primary', specialHide: clearanceView, hideGroup: notCrud, authForce: true },
  { id: 0, code: 'onReselect', namezh: '重新选择', showState: ['list'], width: 100, specialHide: noDeliver, hideGroup: notBillAndRpt },
  { id: 0, code: 'onEdit', namezh: '修改', showState: ['list', 'view'], width: 80, specialHide: clearanceView, hideGroup: notCrud, authForce: true },
  { id: 0, code: 'onDel', namezh: '删除', showState: ['list', 'view'], width: 80, specialHide: clearanceView, hideGroup: notCrud, authForce: true },
  { id: 0, code: 'onCrudCancel', namezh: '取消', showState: ['add', 'edit'], width: 80, hideGroup: notBill, idx: 9, authForce: true },
  { id: 0, code: 'onCrudSave', namezh: '保存', showState: ['add', 'edit'], width: 80, hideGroup: notBill, type: 'primary', idx: 8, authForce: true },

  /** 基于 主数据或设置项的保存/取消按钮 */
  { id: 0, code: 'onCrudCancelMasterOrSetting', namezh: '取消', showState: ['add', 'edit'], width: 80, hideGroup: notMasterOrSetting, idx: 9, authForce: true },
  /** 基于 主数据或设置项的保存/取消按钮 */
  { id: 0, code: 'onCrudSaveMasterOrSetting', namezh: '保存', showState: ['add', 'edit'], width: 80, hideGroup: notMasterOrSetting, type: 'primary', idx: 8, authForce: true },

  // { id: 0, code: 'onView', namezh: '查看单据', showState: ['list'], width: 100, hideGroup: notBill },
  { id: 0, code: 'onAppro', namezh: '审批', showState: ['view'], width: 100, hideGroup: notBill, type: 'text', state: 'normal', authForce: true },
  { id: 0, code: 'onStop', namezh: '停用', showState: ['list', 'view'], width: 80, specialHide: ['employeeSet'], hideGroup: ['home', 'bill', 'setting', 'report'], authForce: true },
  { id: 0, code: 'onClone', namezh: '复制单据', showState: ['list', 'view'], width: 100, specialHide: clearanceView, hideGroup: notBill, authForce: true },
  { id: 0, code: 'onRefreshList', namezh: '刷新', showState: ['list', 'view'], width: 100, specialAllow: ['costRollover'], hideGroup: ['home', 'dict'] },
  { id: 0, code: 'onFitWidth', namezh: '最佳列宽', showState: ['list', 'view'], width: 100, hideGroup: notBillAndRpt },
  { id: 0, code: 'onPrint', namezh: '打印', showState: ['view'], width: 80, authForce: true },

  { id: 0, code: 'onCloneProd', namezh: '物料复制', showState: ['list'], width: 100, specialAllow: ['productMgr'], hideGroup: aloneBtnGropu, authForce: true },

  { id: 0, code: 'onJoin', namezh: '入职', showState: ['list', 'view'], width: 100, specialAllow: ['employeeSet'], hideGroup: aloneBtnGropu, authForce: true },
  { id: 0, code: 'onRegular', namezh: '转正', showState: ['list', 'view'], width: 100, specialAllow: ['employeeSet'], hideGroup: aloneBtnGropu, authForce: true },
  { id: 0, code: 'onRenewal', namezh: '(签)劳动合同', showState: ['list', 'view'], width: 100, specialAllow: ['employeeSet'], hideGroup: aloneBtnGropu, authForce: true },
  { id: 0, code: 'dateDepart', namezh: '离职', showState: ['list', 'view'], width: 100, state: 'danger', specialAllow: ['employeeSet'], hideGroup: aloneBtnGropu, authForce: true },

  { id: 0, code: 'onCommission', namezh: '提成', showState: ['list', 'view'], width: 100, specialAllow: ['counterparty'], hideGroup: aloneBtnGropu, authForce: true },

  { id: 0, code: 'onSaleRebate', namezh: '返利', showState: ['add', 'edit'], width: 100, state: 'danger', rightAlign: true, specialAllow: ['saleInvc'], hideGroup: aloneBtnGropu, authForce: true },
  { id: 0, code: 'onReceiptTrack', namezh: '收款跟踪', showState: ['view'], width: 140, specialAllow: ['saleNotice'], hideGroup: aloneBtnGropu, idx: 1, rightAlign: true, authForce: true },


  { id: 0, code: 'onClearanceManul', namezh: '手工核销', showState: ['list'], width: 100, type: 'primary', rightAlign: true, specialAllow: clearanceView, hideGroup: aloneBtnGropu, authForce: true },
  { id: 0, code: 'onClearanceUndo', namezh: '取消核销', showState: ['list'], width: 100, type: 'outline', rightAlign: true, specialAllow: clearanceView, hideGroup: aloneBtnGropu, authForce: true },
  { id: 0, code: 'onClearanceAuto', namezh: '自动核销', showState: ['list'], width: 100, rightAlign: true, specialAllow: clearanceView, hideGroup: aloneBtnGropu, authForce: true },

  { id: 0, code: 'adjustOpenAmt', namezh: '期初金额调整', showState: ['list'], width: 80, type: 'primary', rightAlign: true, appendClass: 'mx2', specialAllow: ['costRollover'], hideGroup: aloneBtnGropu, authForce: true },
  { id: 0, code: 'adjustEndPrice', namezh: '期末单价调整', showState: ['list'], width: 80, type: 'primary', rightAlign: true, specialAllow: ['costRollover'], hideGroup: aloneBtnGropu, authForce: true },

  { id: 0, code: 'onCarryover', namezh: '结转', showState: ['list'], width: 80, type: 'primary', rightAlign: true, appendClass: 'mx2', specialAllow: ['costCarryover'], hideGroup: aloneBtnGropu, authForce: true },
  { id: 0, code: 'onCarryoverCancel', namezh: '取消结转', showState: ['list'], width: 80, type: 'primary', rightAlign: true, specialAllow: ['costCarryover'], hideGroup: aloneBtnGropu, authForce: true },

  { id: 0, code: 'viewInventory', namezh: '查看库存', showState: ['view', 'add', 'edit'], width: 100, hideGroup: notBill, idx: 3, rightAlign: true, authForce: false },
  { id: 0, code: 'viewCounterPrice', namezh: '往来价格查询', showState: ['view', 'add', 'edit'], width: 100, specialHide: ['saleRebate'], hideGroup: notBill, idx: 3, rightAlign: true, authForce: false },
  { id: 0, code: 'onPush', namezh: '下推', showState: ['list', 'view'], width: 100, hideGroup: notBill, type: 'text', state: 'normal', authForce: true },
  { id: 0, code: 'onPull', namezh: '上引', showState: ['view'], width: 100, hideGroup: notBill, type: 'text', state: 'normal', authForce: true },
  { id: 0, code: 'onExport', namezh: '导出', showState: ['list'], width: 100, rightAlign: true, hideGroup: notBiz },
  // { id: 0, code: 'onImport', namezh: '导入', showState: ['list'], width: 100, specialAllow: ['costRollover'], hideGroup: notBill },
  { id: 0, code: 'onFullDtlView', namezh: '沉浸视图', showState: ['add', 'edit'], width: 80, rightAlign: true, specialHide: clearanceView, hideGroup: notBill },
  { id: 0, code: 'removeNullProd', namezh: '删除无效行', showState: ['add', 'edit'], width: 80, rightAlign: true, specialHide: clearanceView, hideGroup: notBill },
  { id: 0, code: 'onClearDtlRow', namezh: '清空明细行', showState: ['add', 'edit'], width: 80, rightAlign: true, specialHide: clearanceView, hideGroup: notBill },
  { id: 0, code: 'colSetting', namezh: '列配置', showState: ['list'], width: 100, hideGroup: notBill, rightAlign: true },
  { id: 0, code: 'onBackList', namezh: '返回列表', showState: ['view', 'add', 'edit'], width: 80, rightAlign: true, hideGroup: notBill }

  // { id: 0, code: 'onBatch', namezh: '批量操作', showState: ['list', 'view'], width: 100, stop: true, hideGroup: notBill },
  // { id: 0, code: 'onSort', namezh: '排序', showState: ['list'], width: 80, stop: true, hideGroup: notBill },
  // { id: 0, code: 'onBillView', namezh: '通知单清单查询', showState: ['list', 'view'], width: 140, stop: true, hideGroup: notBill },
  // { id: 0, code: 'onPrtTplMgr', namezh: '打印模板管理', showState: ['list', 'view'], width: 128, stop: true, hideGroup: notBill },
  // { id: 0, code: 'onPrtPreview', namezh: '打印预览', showState: ['list', 'view'], width: 100, stop: true, hideGroup: notBill },
  // { id: 0, code: 'onFitWidth', namezh: '最佳列宽', showState: ['list', 'view'], width: 100, specialHide: ['saleRebate', ...clearanceView], specialAllow: ['costRollover'] },
  // { id: 0, code: 'onCloseWindow', namezh: '关闭界面', showState: ['list', 'view'], width: 100, stop: true },
  // { id: 0, code: 'onImport', namezh: '单据导入', showState: ['list', 'view'], width: 100, rightAlign: true, hideGroup: ['home', 'dict', 'master', 'report', 'setting'], stop: true },
  // { id: 0, code: 'onExport', namezh: '单据导出', showState: ['list', 'view'], width: 100, rightAlign: true, hideGroup: ['home', 'dict', 'master', 'report', 'setting'], stop: true },
  // { id: 0, code: 'prodExport', namezh: '商品导出', showState: ['list', 'view'], width: 100, rightAlign: true, hideGroup: ['home', 'dict', 'bill', 'report', 'setting'], specialAllow: ['productMgr'] },
  // { id: 0, code: 'selectAll', namezh: '全选', showState: ['list'], width: 80, type: 'text', rightAlign: true, specialHide: clearanceView, hideGroup: notBill },
  // { id: 0, code: 'selectInvert', namezh: '反选', showState: ['list'], width: 80, type: 'text', rightAlign: true, specialHide: clearanceView, hideGroup: notBill },
];

/** 返回有序的业务操作按钮集合 */
export const BTN_ALL: TOriginBtn[] = btns.map((v: TOriginBtn, k: number) => {
  v.id = k + 1;
  return v;
});
