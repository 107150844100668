const API_ERR: Record<string, string> = {
  'ERR_BAD_RESPONSE': '服务连接失败，请检查服务端或网络状态',
  'Method Not Allowed': 'api请求的方法配置错误',
  'unknown': '未知错误'
};

export const getApiErr = (err: Error & { code: string, response?: { data: any } }): string => {
  return API_ERR[err.code as string]
  || API_ERR[err.response?.data as string]
  || err.message
  || API_ERR.unknown;
};
