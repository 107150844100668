import type { IUseCache } from '@/types/hook/useCache';
import type { IMenuItem, TCrudState, TMenuGoup } from 'shangshi_types';
import type { IPageProfile, IRouterParam, IRouterPushParams, IUseNav, TCrudStatePage, THookUseNav, TRouteName } from '@/types/hook/useNav';
import { isNavigationFailure, useRouter, type HistoryState, type NavigationFailure, type RouteLocationNormalized, type RouteLocationNormalizedLoaded, type RouteLocationRaw, type Router } from 'vue-router';
import { ref, computed, onMounted, nextTick } from 'vue';
import { useCache } from '@/hook';
import type { TCloseDirction } from '@/types/store/useApp';
import { getCompareParameStr, getPageByKey, getStateByKey, identToNavName } from '@/util/routerUtil';
import { HOME_KEY, KEY_STORE_NAV } from '@/constant/sessionKey';
import { ERR_MSG } from '@/constant/err';
import { Toaster } from '@/util/uiKit/toaster';
import type { TBizIdent } from '@/types/alias';
// TODO: 需要在移除页面标签时考虑，是否移除缓存。目前从标签中暂时缺乏视图状态的识别

const shareObj: { routerMgr: null | Router, cacheMgr: null | IUseCache } = {
  routerMgr: null,
  cacheMgr: null
};

const shareCache = (): IUseCache => {
  if (!shareObj.cacheMgr) {
    shareObj.cacheMgr = useCache();
  }
  return shareObj.cacheMgr;
};

const STATE_NAME: Partial<Record<TCrudState, string>> = {
  add: '新增',
  edit: '修改',
  view: '查看'
};

const HOME_KEY_LOW = HOME_KEY.toLowerCase();
/** 判断导航目标是否为首页 */
const isHomePage = (opt: { key?: TRouteName, name?: TRouteName, path?: string }) => ((opt.key)?.toLowerCase() === HOME_KEY_LOW)
  || ((opt.name)?.toLowerCase() === HOME_KEY_LOW)
  || (opt.path === '/');

const getTagName = (to: RouteLocationNormalized, from: RouteLocationNormalized, params?: HistoryState): { namezh: string, btns: string[] } => {
  const { bizIdent: bizIdentTo } = to.meta;
  const { namezh: namezhTo, btns = [] } = shareCache().getMenus(bizIdentTo as TBizIdent) || {};
  const arr: string[] = [];
  if (namezhTo) {
    const { cloneId, pushParam } = params || {};
    const crudState = getStateByKey(to.name as TRouteName);
    if (pushParam && crudState === 'add') { // 若为下推
      const { bizIdent: bizIdentFrom } = from.meta;
      const { namezh: namezhFrom } = shareCache().getMenus(bizIdentFrom as TBizIdent) || {};
      if (namezhFrom) arr.push(namezhFrom, '->');
    }
    arr.push(namezhTo);
    const stateName = STATE_NAME[crudState];
    if (stateName) {
      if (+(cloneId || -1) > 0) { // 若为复制
        arr.push('(复制新增)');
      } else {
        arr.push(`(${stateName})`);
      }
    }
  }
  return { namezh: arr.join(''), btns };
};

const openPages = ref<Map<TRouteName, IPageProfile>>(new Map());
const activeKey = ref<TRouteName>('');
/** 是否已经执行过缓存 */
const cacheDone = ref<boolean>(false);
// const listViewSelectCache = ref<Map<TRouteName, TSelectCache[]>>(new Map());

/** 获取由已打开页面缓存形成的标签数组 */
const pageList = computed(() => {
  const arr = Array.from(openPages.value.values());
  if (!arr.length) {
    activeKey.value = '';
  }
  return arr;
});
const pageInclude = computed(() => pageList.value.map(v => v.keyTo));
/** 获取当前页面的面包屑 */
const breadCrumbs = computed(() => openPages.value.get(activeKey.value)?.breadCrumbs || []);
/** 基于 activeKey 而获取到的活动页面（可能为 undefined） */
const activePage = computed((): IPageProfile | undefined => {
  if (pageList.value.length) {
    const actKey = activeKey.value;
    const page = openPages.value.get(actKey);
    if (!page) {
      console.error(ERR_MSG.stateFail);
      Toaster.warning(ERR_MSG.stateFail);
    }
    return page;
  } else {
    activeKey.value = '';
    return pageList.value[0];
  }
});

// #region 缓存控制
/** 读取缓存 */
const getCache = () => {
  if (!cacheDone.value) {
    const cacheStr = sessionStorage.getItem(KEY_STORE_NAV) || '{}';
    const obj = JSON.parse(cacheStr);
    const objOpenPage = obj.openPages || {};
    openPages.value = new Map(Object.entries(objOpenPage));
    activeKey.value = obj.activeKey || '';
    cacheDone.value = true;
  }
};

/** 写入缓存 */
const setCache = () => {
  const cache: {
    openPages: Record<TRouteName, IPageProfile>,
    activeKey: TRouteName
  } = {
    openPages: Object.fromEntries(openPages.value.entries()),
    activeKey: activeKey.value || ''
  };
  sessionStorage.setItem(KEY_STORE_NAV, JSON.stringify(cache));
};
// #endregion

/** 获取指定的页面描述信息
 *
 * @param bizIdent 目标页面的业务标识
 * @param crudState 目标页面的视图状态
 * @returns
 */
const getPage = (bizIdent: TBizIdent, crudState: TCrudState): IPageProfile | undefined => {
  const keyTo = identToNavName(bizIdent, crudState);
  const page = openPages.value.get(keyTo);
  return page;
};
/** 记录页面 */
const addPage = (to: RouteLocationNormalized, from: RouteLocationNormalized, params?: HistoryState): void => {
  const { bizIdent, group, pathTitle = [] } = to.meta;
  const keyTo = to.name as TRouteName || '';
  const keyFrom = (params?.keyFrom || '') as TRouteName;
  const switchFrom = from.name as TRouteName;

  if (params) params.bizIdentFrom = keyFrom || '';
  const { namezh, btns } = getTagName(to, from, params);
  if (namezh) {
    const pageInfo: IPageProfile = {
      keyTo,
      keyFrom,
      closeStateTo: params?.closeStateTo as TCrudState || null,
      switchFrom,
      namezh,
      path: to.fullPath,
      bizIdent: bizIdent as TBizIdent,
      group: group as TMenuGoup,
      breadCrumbs: pathTitle as string[],
      params: (params || {}) as IRouterParam,
      btns
    };

    openPages.value.set(to.name as TRouteName, pageInfo);
    // activeKey.value = keyTo;
  } else {
    throw new Error('页面获取失败');
  }
};

// funcRemoveTag: (opt: { key?: TRouteName, type: TCloseDirction, actParams?: IRouterParam }) => Promise<void>
const shareRouter = (): Router => {
  if (!shareObj.routerMgr) {
    const r = useRouter();
    r.afterEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void) => {
      try {
        if ((failure?.from?.fullPath !== failure?.to?.fullPath) && isNavigationFailure(failure)) {
          return false;
        } else {
          const existPage = openPages.value.get(to.name as TRouteName);
          const params = history.state.params as IRouterParam;

          if (!existPage) {
            addPage(to, from, params as Record<string, any>);
          } else {
            const strParamsOld = getCompareParameStr(existPage.params);
            const strParamsNow = getCompareParameStr(params);
            if (strParamsOld !== strParamsNow) {
              openPages.value.delete(existPage.keyTo);
              await nextTick();
              addPage(to, from, params as Record<string, any>);
            }
          }
        }
        const toName = to.name;
        activeKey.value = toName !== HOME_KEY ? toName as TRouteName : '';
        setCache();
      } catch (err) {
        from.redirectedFrom;
        return false;
      }
    });

    r.onError((err: any, to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
      Toaster.error((err as  Error).message);
      console.log(`路由异常，发生在： ${from.name as string} -> ${to.name as string}`);
      console.error(err);
    });
    shareObj.routerMgr = r;
  }
  return shareObj.routerMgr;
};

/** 跳转到首页 */
const goToHome = async () => {
  openPages.value.clear();
  await shareRouter().push({ name: HOME_KEY });
  sessionStorage.removeItem(KEY_STORE_NAV);
};

const closeSameBiz = (keyTo: TRouteName): void => {
  const { bizIdent, state: stateTo } = getPageByKey(keyTo);
  // 关闭非目标页面的同业务视图
  const arr = pageList.value.filter(v => {
    const { state } = getPageByKey(v.keyTo);
    return v.bizIdent === bizIdent && state !== stateTo;
  }).map(v => v.keyTo);
  for (const key of arr) {
    openPages.value.delete(key);
  }
};

const navTo = async (keyTo: TRouteName, actionIsPush: boolean, opt?: IRouterParam) => {
  const to: RouteLocationRaw = { name: keyTo };
  if (opt) {
    const params = JSON.parse(JSON.stringify(opt));
    to.state = { params };
  }
  if (activeKey.value !== keyTo) {
    if (!actionIsPush) {
      closeSameBiz(keyTo);
    }
    await shareRouter().push(to);
  } else {
    setCache();
  }
};

const removeTag = async (opt: { key?: TRouteName, type: TCloseDirction, actParams?: IRouterParam }) => {
  const optType = opt.type;
  if (optType === 'all') {
    await goToHome();
  } else if (optType === 'only') {
    const optKey = opt.key;
    if (optKey) {
      await removePolicy.only(optKey, opt.actParams);
    }
  } else {
    const actKey = activePage.value?.keyTo;
    if (actKey) {
      await removePolicy[optType]?.(actKey);
    }
  }
};

/** 标签关闭策略 */
const removePolicy: Record<Exclude<TCloseDirction, 'all'>, (key: TRouteName, actParams?: IRouterParam) => Promise<void>> = {
  only: async (key: TRouteName, actParams?: IRouterParam) => {
    const arrPages = pageList.value;
    const actKey: TRouteName = activeKey.value;
    const arrLen = arrPages.length;
    const opt: { nextKey?: TRouteName, nextPage?: IPageProfile } = {};
    const { bizIdent, state } = getPageByKey(key);
    if (arrLen < 2 && state === 'list') { // 如果当前已打开页面数小于2,则直接返回首页
      await goToHome();
    } else { // 当前已打开页面数 > 1
      if (key !== actKey) { // 待关闭的页面不是活动页面（仅从已打开记录中消除）
        opt.nextKey = actKey;
        opt.nextPage = activePage.value;
        if (opt.nextPage) {
          opt.nextPage.params.billId = actParams?.billId || -1;
        }
      } else { // 待关闭的页面为“当前活动页面”，则除了消除记录外，还需寻找下一个接力页面，并执行跳转
        if (state === 'list') {
          const keyIdx = arrPages.findIndex(v => v.keyTo === key);
          if (keyIdx > 0) {
            opt.nextKey = arrPages[keyIdx - 1].keyTo;
          } else if (keyIdx === 0) {
            opt.nextKey = arrPages[arrLen - 1].keyTo;
          } else {
            opt.nextKey = arrPages[0].keyTo;
          }
          opt.nextPage = openPages.value.get(opt.nextKey);
        } else if (state === 'view') {
          opt.nextKey = identToNavName(bizIdent, 'list');
          opt.nextPage = openPages.value.get(opt.nextKey);
          if (opt.nextPage) {
            opt.nextPage.closeStateTo = null;
          }
        } else if (state === 'add') {
          opt.nextKey = identToNavName(bizIdent, 'list');
          opt.nextPage = openPages.value.get(opt.nextKey);
          if (opt.nextPage) {
            opt.nextPage.closeStateTo = 'list';
            opt.nextPage.params.billId = actParams?.billId || -1;
          }
        } else if (state === 'edit') {
          opt.nextKey = identToNavName(bizIdent, 'view');
          opt.nextPage = openPages.value.get(opt.nextKey);
          if (opt.nextPage) {
            opt.nextPage.closeStateTo = 'list';
            opt.nextPage.params.billId = actParams?.billId || -1;
          }
        }
      }
      if (opt.nextKey) {
        const { state: stateTo } = getPageByKey(opt.nextKey);
        if (opt.nextPage || (['list', 'view'] as TCrudState[]).includes(stateTo)) {
          openPages.value.delete(key);
          await navTo(opt.nextKey, false, openPages.value.get(opt.nextKey)?.params || actParams);
        } else {
          await goToHome();
        }
      }
    }
  },

  left: async (key: TRouteName) => {
    const keys = openPages.value.keys();
    for (const v of keys) {
      if (v === key) {
        break;
      } else {
        openPages.value.delete(v);
      }
    }
    await navTo(activeKey.value, false, activePage.value?.params);
  },

  right: async (key: TRouteName) => {
    const keys = openPages.value.keys();
    let start = false;
    for (const v of keys) {
      if (start) {
        openPages.value.delete(v);
      }
      if (v === key) {
        start = true;
      }
    }
    await navTo(activeKey.value, false, activePage.value?.params);
  },

  except: async (key: TRouteName) => {
    const keys = openPages.value.keys();
    for (const v of keys) {
      if (v !== key) {
        openPages.value.delete(v);
      }
    }
    await navTo(activeKey.value, false, activePage.value?.params);
  }
};

export const useNav: THookUseNav = (): IUseNav => {
  // #region 导航控制
  /** 响应菜单触发的导航
   *
   * @param menuItem 被点击的菜单项所触发的事件载荷
   * @returns
   */
  const navByMenu = async (menuItem: IMenuItem): Promise<void> => {
    const keyTo = isHomePage({ key: menuItem.code }) ? HOME_KEY : menuItem.name;
    await navTo(keyTo, false);
  };

  const getIdFromParam = (params?: IRouterParam, actPage?: IPageProfile): number => +(params?.billId || 0) || +(actPage?.params?.billId || 0) || -1;

  interface INavToStateOpt { stateFrom?: TCrudState, params?: IRouterParam, actPage?: IPageProfile }
  interface INavToStateRes { state: TCrudState, params: IRouterParam }
  const NAV_TO_STATE: Record<TCrudStatePage, (opt?: INavToStateOpt) => INavToStateRes> = {
    add: (opt?: INavToStateOpt): INavToStateRes => {
      const { stateFrom, params, actPage } = opt || {};
      const destObj: INavToStateRes = { state: 'add', params: {} };
      destObj.params.closeStateTo = stateFrom;
      const billId = getIdFromParam(params, actPage);
      if (stateFrom === 'view') destObj.params.idForCancel = billId;
      const cloneId = +(params?.cloneId || -1);
      if (cloneId > 0) destObj.params.cloneId = cloneId;
      return destObj;
    },

    edit: (opt?: INavToStateOpt): INavToStateRes => {
      const { stateFrom, params, actPage } = opt || {};
      const destObj: INavToStateRes = { state: 'edit', params: {} };
      destObj.params.closeStateTo = stateFrom;
      const billId = getIdFromParam(params, actPage);
      if (stateFrom === 'view') destObj.params.idForCancel = billId;
      destObj.params.billId = billId;
      return destObj;
    },

    view: (opt?: INavToStateOpt): INavToStateRes => {
      const { params, actPage } = opt || {};
      const destObj: INavToStateRes = { state: 'view', params: {} };
      destObj.params.closeStateTo = 'list';
      const billId = getIdFromParam(params, actPage);
      if (billId < 1) {
        destObj.state = 'list';
      } else {
        destObj.params.billId = billId;
      }
      return destObj;
    },

    list: (): INavToStateRes => {
      const destObj: INavToStateRes = { state: 'list', params: {} };
      return destObj;
    }
  };

  /** 响应CRUD状态切换下的视图导航
   *
   * @param bizIdent 业务标识
   * @param stateFrom 页面的起始状态
   * @param stateTo 页面的目标状态
   * @param params 导航参数
   * @returns
   */
  const navByCrud = async (bizIdent: TBizIdent, stateFrom: TCrudState, stateTo: TCrudStatePage, params: IRouterParam): Promise<void> => {
    const actPage = openPages.value.get(identToNavName(bizIdent, stateFrom));
    const { state: destState, params: destParams } = NAV_TO_STATE[stateTo]({ stateFrom, params, actPage });
    // 开始执行路由跳转
    const keyTo = identToNavName(bizIdent, destState);
    await navTo(keyTo, false, destParams);
  };

  /** 响应CRUD状态切换下的取消保存的导航
   *
   * @param bizIdent 业务标识
   * @param stateFrom 取消前所处的编辑状态（add or edit）
   * @returns
   */
  const navByCancel = async (bizIdent: TBizIdent, stateFrom: TCrudState): Promise<void> => {
    const keyFrom = identToNavName(bizIdent, stateFrom);
    const actPage = openPages.value.get(keyFrom);
    const actParams: IRouterParam = {};
    const billId = +(actPage?.params.billId || 0) || +(actPage?.params.idForCancel || 0) || 0;
    const closeStateTo = actPage?.params.closeStateTo || 'list';
    const keyTo = identToNavName(bizIdent, billId ? closeStateTo : 'list');
    actParams.billId = billId;
    actParams.closeStateTo = (closeStateTo === 'view' && 'list') || null;
    await navTo(keyTo, false, actParams);
  };

  /** 响应单据的下推
   * @param params 下推所采用的业务参数
   */
  const navToPush = async (params: IRouterPushParams): Promise<void> => {
    if (params.fromDtlIds.length) {
      const bIdentTo = params.bizIdent.to;
      const keyTo = identToNavName(bIdentTo, 'add');
      const arr = pageList.value.filter(v => v.bizIdent === bIdentTo).map(v => v.keyTo);
      for (const v of arr) {
        await removeTag({ key: v, type: 'only' });
      }
      await navTo(keyTo, true, { pushParam: params });
    } else {
      Toaster.error(ERR_MSG.pushSourceIdsMiss);
    }
  };

  const navToPull = async (bizIdentTo: string): Promise<void> => {
    const keyTo = identToNavName(bizIdentTo, 'list');
    await navTo(keyTo, true);
  };

  const backToList = async (bizIdent: TBizIdent): Promise<void> => {
    const keyTo = identToNavName(bizIdent, 'list');
    await navTo(keyTo, false);
  };
  // #endregion

  // #region 标签控制
  const switchTag = async (keyTo: TRouteName): Promise<void> => {
    const page = openPages.value.get(keyTo);
    if (page) {
      if (activeKey.value !== keyTo) {
        await navTo(keyTo, false, page.params);
      }
    }
  };
  // #endregion

  // #region 私有控制
  const destroy = async () => {
    await goToHome();
  };
  // #endregion

  onMounted(async () => {
    getCache();
    const r = shareRouter();
    await r.isReady();
  });

  return {
    activeKey,
    pageList,
    pageInclude,
    breadCrumbs,
    getPage,
    navByMenu,
    navByCrud,
    navByCancel,
    navToPush,
    navToPull,
    backToList,
    switchTag,
    removeTag,
    destroy
  };
};
